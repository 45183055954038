import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsMapService } from './us-map.service';
import { UsMapComponent } from './us-map.component';


export class UsMapModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [
    CommonModule
  ],
  declarations: [UsMapComponent],
  providers: [UsMapService],
  exports: [UsMapComponent]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
